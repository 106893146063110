import createGenericClient from '../../genericClient';

import ErrorLayout from '../../layouts/ErrorLayout';
import components from '../../components/errors/NotFound';

const bundle = {};
const layout = ErrorLayout;
bundle[layout.bundleName] = ErrorLayout;
bundle[components.bundleName] = components;
createGenericClient(bundle);
