import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class GenericRouteHandler extends Component {
  static propTypes = {
    routeHandler: PropTypes.object,
  };

  static childContextTypes = {
    routeHandler: PropTypes.object,
  };

  static getPrefetchPaths = function (models, options, props) {
    let paths = [];
    if (typeof _.get(props, 'model.templateComponent.getPrefetchPaths') === 'function') {
      paths = _.concat(paths, props.model.templateComponent.getPrefetchPaths(models, options, props));
    }
    if (typeof _.get(props, 'model.layoutComponent.getPrefetchPaths') === 'function') {
      paths = _.concat(paths, props.model.layoutComponent.getPrefetchPaths(models, options, props));
    }
    return paths;
  };

  constructor(props, context) {
    super(props, context);
  }

  getChildContext() {
    return {
      routeHandler: this.props.routeHandler,
    };
  }

  render() {
    const { model } = this.props;
    let element = this.props.children;
    if (model.templateComponent) {
      element = React.createElement(
        model.templateComponent,
        Object.assign({}, _.get(this.props, 'model.models.templateProps', {}), this.props),
        element,
      );
    }
    if (model.layoutComponent) {
      element = React.createElement(
        model.layoutComponent,
        Object.assign({}, _.get(this.props, 'model.models.layoutProps', {}), this.props),
        element,
      );
    }
    return element;
  }
}

export default GenericRouteHandler;
