import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Footer from '../../common/components/Footer';
import Header from '../../common/components/Header';
import layoutContext from '../../common/hocs/layoutContext';
import _ from "lodash";
import window from 'global/window';
import getColumnsInRow from "../../common/utils/getColumnsInRow";
import * as browserEvents from "../../../sketch-platform/utils/browserEvents";

class ErrorLayout extends Component {
  static bundleName = 'layouts/ErrorLayout';

  static contextTypes = {
    models: PropTypes.object,
    getModelData: PropTypes.func,
    routeHandler: PropTypes.object,
  };

  static childContextTypes = {
    spMode: PropTypes.bool,
    columnsInRow: PropTypes.number,
    routeHandler: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    const link = this.model.getSync(['link', 'ntv']);
    this.trentaHeaderMenu = _.get(link, 'nnn_header_menu');
    this.footerLink = _.get(link, 'nnn_footer_link')

    this.checkMediaQuery = this.checkMediaQuery.bind(this);

    this.state = {
      columnsInRow: 5,
      spMode: false,
    };

    const browserInfo = this.context.getModelData('browserInfo');

    // スマホの場合は予め2を指定する
    if ((browserInfo.isAndroid || browserInfo.isIOS) && !(
      browserInfo.isAndroidTablet || browserInfo.isFireTablet || browserInfo.isIPad
    )) {
      this.state.columnsInRow = 2;
      this.state.spMode = true;
    }
  }

  getChildContext() {
    return {
      columnsInRow: this.state.columnsInRow,
      routeHandler: this.context.routeHandler,
      spMode: this.state.spMode,
    }
  }

  checkMediaQuery() {
    if (typeof window === 'undefined') return;

    if (this.checkMediaQueryTimerId) clearTimeout(this.checkMediaQueryTimerId);

    this.checkMediaQueryTimerId = setTimeout(() => {
      let columnsInRow = 1;
      let spMode = false;
      columnsInRow = getColumnsInRow();
      if (columnsInRow === 2) {
        spMode = true;
      }
      const browserInfo = this.context.getModelData('browserInfo');
      if ((browserInfo.isAndroid || browserInfo.isIOS) && !(
        browserInfo.isAndroidTablet || browserInfo.isFireTablet || browserInfo.isIPad
      )) {
        spMode = true;
        Object.assign(this.state, { spMode });
      }

      if (columnsInRow !== this.state.columnsInRow) {
        if (this._isMounted) {
          this.setState({ columnsInRow, spMode });
        } else {
          Object.assign(this.state, { columnsInRow, spMode });
        }
      }
    }, 100); // 回転前の値で処理する等、安定しないので、タイマーを用いて回転後の処理になるよう調整
  }

  componentDidMount() {
    this._isMounted = true;

    const newState = {};

    // checkMediaQueryを呼ぶと遅いので直接処理
    newState.columnsInRow = getColumnsInRow();
    if (newState.columnsInRow === 2) {
      newState.spMode = true;
    }

    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.addEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.addEventListener('resize', this.checkMediaQuery);
    }

    if ((browserInfo.isIOS || browserInfo.isAndroid) && !(
      browserInfo.isAndroidTablet || browserInfo.isFireTablet || browserInfo.isIPad
    )) {
      newState.spMode = true;
    }

    if (!_.isEmpty(newState)) this.setState(newState);
  }

  componentWillUnmount() {
    this._isMounted = false;
    const browserInfo = this.context.getModelData('browserInfo');
    if (browserInfo.isIOS || browserInfo.isAndroid) {
      browserEvents.removeEventListener('orientationchange', this.checkMediaQuery);
    } else {
      browserEvents.removeEventListener('resize', this.checkMediaQuery);
    }

    if (this.checkMediaQueryTimerId) clearTimeout(this.checkMediaQueryTimerId);
  }

  static getPrefetchPaths = function(models, options, props) {
    return [
      ['link', 'ntv', 'nnn_header_menu'],
      ['link', 'ntv', 'nnn_footer_link'],
      ['article','getAttention'],
    ];
  };

  render() {
    const inapp = this.context.getModelData('inapp', 'inapp');
    return (
      <div className={classnames('login-wrapper', { inapp })}>
        <Header model={this.model} routeHandler={this.context.routeHandler} trentaHeaderMenu={this.trentaHeaderMenu} invisibleAd={true} />
        <div className="mainView">{this.props.children}</div>
        {!inapp ? <Footer footerLink={this.footerLink}/> : null}
      </div>
    );
  }
}

const root = layoutContext(ErrorLayout);
root.getPrefetchPaths = ErrorLayout.getPrefetchPaths;
export default root;
