import 'core-js/es/map';
import 'core-js/es/set';
import _ from 'lodash';
import Fluxxor from 'fluxxor';

import history from '../history';
import genericApp from './genericApp';
import { ReactClientRenderer } from '../../sketch-platform/renderers';
import PathEvaluator from '../../sketch-platform/utils/PathEvaluator';
import FalcorDataSource from 'falcor-http-datasource';
import * as COOKIE from '../../constants/cookie';

export default function createGenericClient(bundle) {

  window.app = window.app || {};
  window.app.reactContext = window.app.reactContext || {};

  const endpoint = '/pathEvaluator';
  const pathEvaluator = new PathEvaluator({
    collectRatio: 0.75,
    source: new FalcorDataSource(endpoint, {
      headers: {
        'If-Modified-Since': 'Thu, 01 Jan 1970 00:00:00 GMT',
      },
    }),
    errorSelector(error) {
      console.error('errorSelector', error);
      error.$expires = -1000 * 60 * 2;
    },
  });
  pathEvaluator.setCache(window.app.falcorCache);

  const model = _.assign({pathEvaluator}, _.cloneDeep(window.app.reactContext));
  if (model.layout && bundle[model.layout]) {
    model.layoutComponent = bundle[model.layout];
  }
  if (model.template && bundle[model.template]) {
    model.templateComponent = bundle[model.template];
    const stores = {};
    _.get(model.templateComponent.__meta__, ['fluxxor', 'stores'], []).map((storeName) => {
      const storeClass = bundle[storeName];
      stores[storeName.split('/').pop()] = new storeClass();
    });
    const actions = {};
    _.get(model.templateComponent.__meta__, ['fluxxor', 'actions'], []).map((actionName) => {
      actions[actionName.split('/').pop()] = bundle[actionName];
    });
    model.flux = new Fluxxor.Flux(stores, actions);
  }

  const container = document.getElementById('appMountPoint');

  const appContext = genericApp.createContext({model, renderSource: "client", history});

  window.onpageshow = function(event) {
  };

  new ReactClientRenderer(container, appContext).render((err, a) => {
    if (err) {
      console.error(err);
    } else {
      console.log(a);
    }
  });
};
