import _ from 'lodash';
import App from '../../sketch-platform/app/App';
import { createRoute } from '../../sketch-platform/ui/routing';
import routes from '../common/routes';
import GenericRouteHandler from './GenericRouteHandler';

export default new App(
  {
    routeHandlers: [
      {
        route: createRoute({ path: '(.*)' }),
        components: [GenericRouteHandler],
      },
    ],
    routes,
  },
  {
    handleCreateContext: function (context) {
      return context;
    },
    handleRouteChange: function (state, routeHandler) {
      return state;
    },
  },
);
